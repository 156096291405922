import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Text, { m, ml, l } from '../styles/typography';
import Layout from '../components/layout';
import Grid from '../components/grid';
import SEO from '../components/seo';
import { colors, spacing, minWidthCss } from '../styles/theme';
import PlayIcon from '../images/icons/play-icon';

const Container = styled.div`
	margin: 0 15px;

	${minWidthCss.m`
    margin: 0 85px;
  `}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${spacing[5]} 0;
`;

const HeaderText = styled(Text.H1)`
	font-size: 45px;
	padding: ${spacing[1]};

	${minWidthCss.m`
        font-size: 120px;
  `};
`;

const ItemContainer = styled(Link)`
	text-decoration: none;
	color: black;
	font-size: 20px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	text-align: center;

	> img {
		width: 100%;
	}
`;

const VideoImage = styled.div`
	background-image: url(${props => props.url});
	background-position: center;
	background-size: cover;
	padding-top: 66.77%;
	flex: 1;
`;

const Title = styled(Text.H2)`
	color: ${colors.white};
	margin-top: ${spacing[2]};
	${ml};

	${minWidthCss.sm`
    margin-top: 1.875rem;
    ${l};
  `};
`;

const Subtitle = styled(Text.Span)`
	${m};

	${minWidthCss.sm`
    margin-top: 0.3125rem;
    ${ml};
`};
`;

const ImageWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	> svg {
		position: absolute;
	}
`;

const AristTemplate = ({ data, pageContext }) => {
	const { title, slug } = pageContext;
	const artistData = data.allLecturesJson.nodes;

	return (
		<Layout>
			<SEO
				pathname={`/artist/${slug}`}
				title={title
					.split('|')[0]
					.trim()
					.replace(/&amp;/g, '&')
					.replace(/&nbsp;/g, ' ')}
			/>
			<HeaderContainer>
				<HeaderText>{title}</HeaderText>
			</HeaderContainer>
			<Container>
				<Grid columns={2}>
					<For each="lecture" of={artistData}>
						<ItemContainer to={`/lectures/${lecture.currentSlug}`}>
							<ImageWrapper>
								<VideoImage
									aria-labelledby={lecture.title}
									role="img"
									url={`"https://media.redbullmusicacademy.com/assets/${lecture.teaserImage.id.replace(
										/\s/g,
										'%20'
									)}"`}
								/>
								<PlayIcon />
							</ImageWrapper>
							<Title>{lecture.title}</Title>
							<Subtitle color="white">{lecture.video.caption}</Subtitle>
						</ItemContainer>
					</For>
				</Grid>
			</Container>
		</Layout>
	);
};

AristTemplate.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

export default AristTemplate;

export const artistQuery = graphql`
	query artistQuery($title: String!) {
		allLecturesJson(filter: { title: { eq: $title } }) {
			nodes {
				title
				year
				_id
				currentSlug
				teaserImage {
					id
				}
				video {
					caption
				}
			}
		}
	}
`;
