import React from 'react';

const ArrowUpGold = props => (
	<svg fill="none" height={42} viewBox="0 0 25 42" width={25} {...props}>
		<path
			d="M23.253 10.557L25 7.61 12.466 0 0 7.61l1.714 2.947 9.15-5.724-.022 15.676-9.128 5.644L3.427 29.1l7.404-4.753v2.616l-9.117 5.644 1.713 2.937 7.404-4.742-.011 2.616-9.106 5.633L3.427 42l9.05-5.804L21.506 42l1.748-2.948-9.151-5.633v-2.627l7.403 4.753 1.748-2.937-9.151-5.644v-2.628l7.403 4.765 1.748-2.948-9.151-5.633V4.833l9.15 5.724z"
			fill="#FFF"
		/>
	</svg>
);

export default ArrowUpGold;
