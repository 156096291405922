class DataTransformer {
	constructor(data) {
		this.data = data.filter(item => item);
	}

	static from(data) {
		return new DataTransformer(data);
	}

	applyImageFilter() {
		const withImages = this.data.filter(item => item.teaserImage !== null);
		return new DataTransformer(withImages);
	}

	getDataByPage(page, itemsPerPage) {
		return this._getByPage(page, itemsPerPage);
	}

	_getByPage(page, itemsPerPage) {
		const limit = page * itemsPerPage;
		return {
			items: this.data.filter((_, index) => index < limit),
			hasMore: this.data.length > limit,
		};
	}
}

export default DataTransformer;
