import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				defaultDescription: description
				siteUrl: url
				defaultImage: image
				twitterUsername
			}
		}
	}
`;

const SEO = ({ title, description, image, lang, pathname, article }) => (
	<StaticQuery
		query={query}
		render={({
			site: {
				siteMetadata: {
					defaultTitle,
					defaultDescription,
					siteUrl,
					defaultImage,
					twitterUsername,
				},
			},
		}) => {
			const seo = {
				title: title || defaultTitle,
				description: description || defaultDescription,
				image: `https://media.redbullmusicacademy.com/assets/${image ||
					defaultImage}`,
				url: `${siteUrl}${pathname || '/'}`,
			};
			const titleTemplate = `%s | ${defaultTitle}`;
			return (
				<>
					<Helmet
						defaultTitle={defaultTitle}
						htmlAttributes={{ lang }}
						title={title}
						titleTemplate={titleTemplate}
					>
						<meta content={seo.description} name="description" />
						<meta content={seo.image} name="image" />
						<If condition={seo.url}>
							<meta content={seo.url} property="og:url" />
						</If>
						<If condition={article}>
							<meta content="article" property="og:type" />
						</If>
						<If condition={seo.title}>
							<meta content={seo.title} property="og:title" />
						</If>
						<If condition={seo.description}>
							<meta content={seo.description} property="og:description" />
						</If>
						<If condition={seo.image}>
							<meta content={seo.image} property="og:image" />
						</If>
						<meta content="summary_large_image" name="twitter:card" />
						<If condition={twitterUsername}>
							<meta content={twitterUsername} name="twitter:creator" />
						</If>
						<If condition={seo.title}>
							<meta content={seo.title} name="twitter:title" />
						</If>
						<If condition={seo.description}>
							<meta content={seo.description} name="twitter:description" />
						</If>
						<If condition={seo.image}>
							<meta content={seo.image} name="twitter:image" />
						</If>
						<link
							href="/apple-touch-icon.png"
							rel="apple-touch-icon"
							sizes="180x180"
						/>
						<link
							href="/favicon-32x32.png"
							rel="icon"
							sizes="32x32"
							type="image/png"
						/>
						<link
							href="/favicon-16x16.png"
							rel="icon"
							sizes="16x16"
							type="image/png"
						/>
						<link href="/site.webmanifest" rel="manifest" />
						<link
							color="#000000"
							href="/safari-pinned-tab.svg"
							rel="mask-icon"
						/>
						<meta content="#000000" name="msapplication-TileColor" />
						<meta content="#ffffff" name="theme-color" />
					</Helmet>
				</>
			);
		}}
	/>
);

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	lang: PropTypes.string,
	pathname: PropTypes.string,
	article: PropTypes.bool,
};

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	lang: 'en',
	pathname: null,
	article: false,
};

export default SEO;
