import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Text, { m, ml, l, xl } from '../styles/typography';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { colors, spacing, minWidthCss } from '../styles/theme';

const Container = styled.div`
	margin: 0 15px;
	position: relative;

	${minWidthCss.m`
    margin: 0 85px;
  `}
`;

const HeaderContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: ${spacing[4]};

	${minWidthCss.m`
    align-items: flex-end;
    margin: ${spacing[5]};
  `};
`;

const HeaderText = styled(Text.H1)`
	margin: 0 ${spacing[1]} 12.5rem ${spacing[1]};
	${l};
	z-index: 2;

	${minWidthCss.m`
    margin-bottom: ${spacing[4]};
    width: 60%;
    ${xl};
  `};

	${minWidthCss.l`
    margin: 0 ${spacing[5]} ${spacing[4]} ${spacing[5]};
    width: 40%;
  `};
`;

const DescriptionText = styled(Text.P)`
	margin: 0 ${spacing[1]} ${spacing[0]} ${spacing[1]};
	${m};
	z-index: 2;

	> a {
		text-decoration: underline;
	}

	${minWidthCss.m`
    width: 60%;
    ${ml};
  `};

	${minWidthCss.l`
    width: 40%;
    margin: 0 ${spacing[5]} ${spacing[0]} ${spacing[5]};
  `};
`;

const HeaderImage = styled.img`
	opacity: 0.4;
	position: absolute;
	width: 100%;
	top: 8.125rem;

	${minWidthCss.m`
    left: 0;
    top: 0;
    width: 60%;
  `};
`;

const ProjectItem = styled(Link)`
	text-decoration: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 3rem;
	border-bottom: 1px ${colors.white} solid;
	border-top: ${props => (props.isTop ? `1px ${colors.white} solid` : 'none')};
	color: ${colors.white};

	&:hover {
		color: ${colors.black};
		background-color: ${colors.white};
	}

	> span {
		${m};

		${minWidthCss.m`
        ${l};
    `};
	}

	${minWidthCss.m`
    height: ${spacing[4]};
    border-top: ${props =>
			props.isTop ? `2px ${colors.white} solid` : 'none'};
    border-bottom: 2px ${colors.white} solid;
  `};
`;

const AboutPage = ({ data }) => {
	const aboutData = data.allProjectsJson.nodes[0].doc;
	const projects = data.allPagesJson.nodes;
	const getYear = id => {
		const entry = aboutData.projects.find(({ projectId }) => projectId === id);
		if (entry) return parseInt(entry.year, 10);
	};
	const getTitle = id => {
		const entry = aboutData.projects.find(({ projectId }) => projectId === id);
		if (entry) return entry.title;
	};
	projects.sort((a, b) => {
		if (getYear(a.id) > getYear(b.id)) return -1;
		if (getYear(a.id) < getYear(b.id)) return 1;
		return 0;
	});
	return (
		<Layout>
			<SEO image={aboutData.mainImage.id} pathname="/about" title="About" />
			<HeaderContainer>
				<HeaderImage
					src={`https://media.redbullmusicacademy.com/assets/${aboutData.mainImage.id}`}
				/>
				<HeaderText>{aboutData.intro}</HeaderText>
				<DescriptionText>
					Every year, artists from around the world came together in a different
					host city to learn from musical luminaries – and each other – and
					collaborate in custom-built studios. Many Academy alumni went on to
					become leaders of their scenes locally and internationally, while each
					edition left behind structures to encourage musical collaboration and
					creative exchange for years to come.
				</DescriptionText>
				<DescriptionText>
					In addition to the annual Academy, RBMA presented citywide festivals,
					stages, club nights, workshops and other events in more than 60
					countries each year. It was also home to a radio station,{' '}
					<a href="https://www.mixcloud.com/redbullradio/">Red Bull Radio</a>,
					broadcasting local music scenes to global audiences, and an editorial
					platform, RBMA Daily, which lives on as an unmatched archive and
					source of knowledge for music-makers and music lovers everywhere.
				</DescriptionText>
				<DescriptionText>
					Check out some highlights from Red Bull Music Academy&rsquo;s 20-year
					history below.
				</DescriptionText>
			</HeaderContainer>
			<Container>
				<For each="project" index="index" of={projects}>
					<ProjectItem
						isTop={getYear(project.id) === 2019}
						key={`${project.title}_${index}`}
						to={project.slug}
					>
						<Text.Span>{getTitle(project.id)}</Text.Span>
						<Text.Span>({getYear(project.id)})</Text.Span>
					</ProjectItem>
				</For>
			</Container>
		</Layout>
	);
};

AboutPage.propTypes = {
	data: PropTypes.object,
};

export default AboutPage;

export const aboutQuery = graphql`
	query aboutQuery {
		allProjectsJson {
			nodes {
				doc {
					intro
					mainImage {
						id
					}
					slug
					projects {
						projectId
						year
						title
					}
				}
			}
		}
		allPagesJson(filter: { slug: { regex: "/projects/" } }) {
			nodes {
				title
				slug
				id
			}
		}
	}
`;
