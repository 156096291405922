import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import DataTransformer from '../utils/DataTransformer';
import usePagination from '../hooks/usePagination';
import Text, { m, ml, l } from '../styles/typography';
import Layout from '../components/layout';
import Grid from '../components/grid';
import SEO from '../components/seo';
import { colors, spacing, minWidthCss } from '../styles/theme';
import { Button } from '../styles/buttons';
import PlayIcon from '../images/icons/play-icon';

const Container = styled.div`
	margin: 0 15px;

	${minWidthCss.m`
    margin: 0 85px;
  `}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${spacing[5]} 0;
`;

const HeaderText = styled(Text.H1)`
	font-size: 45px;
	padding: ${spacing[1]};

	${minWidthCss.m`
        font-size: 120px;
  `};
`;

const ItemContainer = styled.a`
	text-decoration: none;
	color: black;
	font-size: 20px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	text-align: center;

	> img {
		width: 100%;
	}
`;

const VideoImage = styled.div`
	background-image: url(${props => props.url});
	background-position: center;
	background-size: cover;
	padding-top: 66.77%;
	flex: 1;
`;

const Title = styled(Text.H2)`
	color: ${colors.white};
	margin-top: ${spacing[2]};
	${ml};

	${minWidthCss.sm`
    margin-top: 1.875rem;
    ${l};
  `};
`;

const Subtitle = styled(Text.Span)`
	${m};

	${minWidthCss.sm`
    margin-top: 0.3125rem;
    ${ml};
`};
`;

const Wrapper = styled.div`
	margin: 20px 0 40px;
	text-align: center;

	${minWidthCss.sm`
    margin: 40px 20px 80px;
  `}
`;

const ImageWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	> svg {
		position: absolute;
	}
`;

const YearTemplate = ({ data, pageContext, onNextPage, hasNextPage }) => {
	const { year } = pageContext;
	const yearData = data.allLecturesJson.nodes;
	const itemsPerPage = 6;
	const [page, nextPage] = usePagination();

	const loadMore = async evt => {
		evt.preventDefault();
		if (onNextPage) onNextPage(page + 1);
		nextPage();
	};

	const { items, hasMore } = DataTransformer.from(yearData).getDataByPage(
		page,
		itemsPerPage
	);

	return (
		<Layout>
			<SEO pathname={`/lectures/${year}`} title={year} />
			<HeaderContainer>
				<HeaderText>{year}</HeaderText>
			</HeaderContainer>
			<Container>
				<Grid columns={2}>
					<For each="lecture" of={items}>
						<ItemContainer href={`/lectures/${lecture.currentSlug}`}>
							<ImageWrapper>
								<VideoImage
									aria-labelledby={lecture.title}
									role="img"
									url={`"https://media.redbullmusicacademy.com/assets/${lecture.teaserImage.id.replace(
										/\s/g,
										'%20'
									)}"`}
								/>
								<PlayIcon />
							</ImageWrapper>
							<Title>{lecture.title}</Title>
							<Subtitle color="white">{lecture.video.caption}</Subtitle>
						</ItemContainer>
					</For>
				</Grid>
				<If condition={hasNextPage || hasMore}>
					<Wrapper>
						<Button onClick={loadMore} underline>
							Load more
						</Button>
					</Wrapper>
				</If>
			</Container>
		</Layout>
	);
};

YearTemplate.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	onNextPage: PropTypes.func,
	hasNextPage: PropTypes.bool,
};

export default YearTemplate;

export const yearQuery = graphql`
	query yearQuery($year: Date) {
		allLecturesJson(filter: { year: { eq: $year } }) {
			nodes {
				title
				year
				_id
				currentSlug
				teaserImage {
					id
				}
				video {
					caption
				}
			}
		}
	}
`;
