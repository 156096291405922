import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { minWidthCss } from '../styles/theme';

const Layout = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 0;

	> a {
		width: calc(100%);
		margin: ${props => (props.isTextOnly ? 0 : '0 0 2.5rem')};

		${minWidthCss.sm`
      flex-grow: 0;
      margin: ${props => (props.isTextOnly ? 0 : '0 20px 60px 20px')} ;
      width: ${props => (props.isTextOnly ? '50%' : 'calc(50% - 2.5rem)')};
    `}

		${minWidthCss.l`
      width: ${props =>
				`calc(${100 / props.columns}% - ${
					props.isTextOnly ? '0rem' : '2.5rem'
				})`};
      justify-content: ${props => (props.isTextOnly ? 'space-between' : null)}
    `}
	}
`;

const Grid = ({ children, columns, isTextOnly = false }) => (
	<Layout columns={columns} isTextOnly={isTextOnly}>
		{children}
	</Layout>
);

Grid.defaultProps = {
	columns: 2,
};

Grid.propTypes = {
	children: PropTypes.node.isRequired,
	columns: PropTypes.number,
	isTextOnly: PropTypes.bool,
};

export default Grid;
