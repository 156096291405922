import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { initVideos } from '../utils/video';

import Layout from '../components/layout';
import LinkItems from '../components/link-items';
import SEO from '../components/seo';
import Text, { l, ml } from '../styles/typography';
import { spacing, minWidthCss } from '../styles/theme';
import './legacy.css';

const RelatedDocumentsTitle = styled(Text.H2)`
  text-align: center;
  ${ml}
  margin-bottom: ${spacing[1]};

  ${minWidthCss.sm`
    margin-bottom: ${spacing[2]};
    ${l}
  `}
`;

const Page = ({ data }) => {
	const { body, metaImage, slug, title, relatedDocuments } = data.pagesJson;
	useEffect(() => {
		initVideos(data.pagesJson.data);
		const spinner = document.querySelector('.spinner');
		if (spinner) {
			spinner.parentElement.removeChild(spinner);
		}
	});
	return (
		<Layout>
			<SEO
				image={metaImage}
				pathname={slug}
				title={title
					.split('|')[0]
					.trim()
					.replace(/&amp;/g, '&')
					.replace(/&nbsp;/g, ' ')}
			/>
			<Helmet>
				<script defer src="/scripts/main.js" />
			</Helmet>
			<div dangerouslySetInnerHTML={{ __html: body }} />
			<RelatedDocumentsTitle size="l">
				On a different note
			</RelatedDocumentsTitle>
			<LinkItems columns={2} data={relatedDocuments} />
		</Layout>
	);
};

Page.propTypes = {
	data: PropTypes.object,
};

export default Page;

export const pageQuery = graphql`
	fragment video on Video {
		url
		service
		id
		caption
	}
	fragment block on Content {
		schema
		video {
			...video
		}
		poster {
			id
			size
			mimetype
		}
		blockN
	}
	query pagesJson($slug: String!) {
		pagesJson(slug: { eq: $slug }) {
			id
			title
			body
			slug
			metaImage
			relatedDocuments {
				title
				teaserTitle
				teaserSubtitle
				teaserImage {
					id
				}
				slug
				date
				type
			}
			data {
				videos {
					...block
				}
				transcripts {
					...block
				}
				video {
					...video
				}
			}
			meta {
				name
				content
			}
		}
	}
`;
