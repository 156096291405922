import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { minWidthCss, spacing } from '../styles/theme';

import Navigation from './navigation';
import Footer from './footer';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding-bottom: 8.75rem; /* Leave space for footer */
`;

const Container = styled.div`
	margin: 0 auto;
	overflow: hidden;

	> main {
		display: block;
		margin-top: ${props => (props.isHomepage ? 0 : '9.375rem')};
	}
`;

export const ContainerWithMargin = styled.div`
	padding: 0 ${spacing[1]};

	${minWidthCss.m`
  padding: 0 3.125rem;
`}
`;

const Layout = ({ children, isHomepage, isHomepageBanner }) => (
	<Wrapper>
		<Container isHomepage={isHomepage}>
			<Navigation isHomepageBanner={isHomepageBanner} />
			<main>{children}</main>
			<Footer />
		</Container>
	</Wrapper>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	isHomepage: PropTypes.bool,
	isHomepageBanner: PropTypes.bool,
};

export default Layout;
