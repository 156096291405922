import React from 'react';

const MobileLink = props => (
	<svg fill="none" height={29} width={29} {...props}>
		<path
			d="M19.212 7.635l1.838 1.838L6.909 23.616 5.07 21.777 19.212 7.635z"
			fill="#fff"
		/>
		<path d="M22.707 6v2.6h-12V6h12z" fill="#fff" />
		<path d="M22.707 18h-2.6V6h2.6v12z" fill="#fff" />
	</svg>
);

export default MobileLink;
