import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import Text, { m, ml, l, xl, xxl, xxxl } from '../styles/typography';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { minWidthCss, spacing, colors } from '../styles/theme';
import { WhiteButton } from '../styles/buttons';

const HeroImageContainer = styled.div`
	position: relative;
	height: 80vh;
	width: 100vw;
`;

const HeroImage = styled.div`
	position: relative;
	width: 100vw;
`;

const HeroImageText = styled.div`
	z-index: 3;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 ${spacing[2]};
	height: 80vh;

	${minWidthCss.m`
    padding: 0 ${spacing[5]};
  `};

	${minWidthCss.l`
    padding: 0 12.50rem;
  `};
`;

const Title = styled(Text.H1)`
	${l};
	margin: ${spacing[2]} 0;

	${minWidthCss.m`
    ${xl};
  `};

	${minWidthCss.l`
    ${xxl};
  `};

	${minWidthCss.xl`
    ${xxxl};
  `};
`;

const Subtitle = styled(Text.H2)`
	${props => (props.welcome ? `${ml}` : `${m}`)};
	margin-bottom: ${spacing[2]};

	${minWidthCss.sm`
    ${ml};
  `};

	${minWidthCss.m`
    ${l};
  `};
`;

const TopicTitles = styled(Text.P)`
	margin: 0 auto;
	max-width: 36.35rem;
	${ml};

	margin-bottom: ${spacing[2]};
	${ml};

	${minWidthCss.s`
    ${ml};

  `};
	${minWidthCss.m`
    margin-bottom: ${spacing[3]};
    max-width: 26.35rem;
    ${l};
  `};

	${minWidthCss.l`
    ${xl};
    max-width: 36.35rem;
  `};
`;

const Welcome = styled.div`
	text-align: center;
	margin: ${spacing[6]} 1rem;
	max-width: 64rem;

	${minWidthCss.m`
    margin: ${spacing[6]} auto;
`};
`;

const OneToTwoGrid = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;

	${minWidthCss.m`
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  `};
`;

const ImageLinkWrapper = styled(Link)`
	width: 100vw;

	${minWidthCss.m`
    width: 50%;
  `};
`;

const LinkWrapper = styled.a`
	width: 100vw;

	${minWidthCss.m`
    width: 50%;
  `};
`;

const StoryText = styled.div`
	position: relative;
	margin: ${spacing[3]} 1rem ${spacing[5]} 1rem;
	justify-content: center;
	display: flex;
	flex-direction: column;

	> a {
		margin: 0 auto;
		border-bottom: 1px ${colors.white} solid;
		${m};
	}

	${minWidthCss.m`
    width: 50%;
    margin: 0;

    > a {
      ${ml};
    }
  `};
`;

const placeholder =
	'data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAABAAEDAREAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD+OegA/9k=';

const ImgItem = ({ imgSrc }) => {
	return (
		<Img
			fluid={{
				base64: placeholder,
				aspectRatio: 1,
				src: `${imgSrc}`,
				srcSet: `${imgSrc}?h=500&w=500 375w,\n${imgSrc}?h=950&w=950 600w,\n${imgSrc}?h=800&w=800 900w,\n${imgSrc}?h=950&w=950 1200w`,
				sizes: '(max-width: 800px) 100vw, 800px',
			}}
			loading="eager"
		/>
	);
};

const IndexPage = () => {
	const [isBanner, setIsBanner] = useState(true);

	useScrollPosition(
		({ currPos }) => {
			if (currPos.y > -670) {
				setIsBanner(true);
			} else {
				setIsBanner(false);
			}
		},
		[isBanner]
	);

	return (
		<Layout isHomepage isHomepageBanner={isBanner}>
			<SEO />
			<HeroImageContainer>
				<HeroImage>
					<Img
						fluid={{
							base64: placeholder,
							src: 'https://media.redbullmusicacademy.com/assets/HEADER_1.jpg',
							srcSet:
								'https://media.redbullmusicacademy.com/assets/HEADER_1.jpg?h=300&w=540 375w,\n https://media.redbullmusicacademy.com/assets/HEADER_1.jpg?h=356&w=640 600w,\n https://media.redbullmusicacademy.com/assets/HEADER_1.jpg?h=578&w=1040 900w,\n https://media.redbullmusicacademy.com/assets/HEADER_1.jpg?h=800&w=1440 1200w',
							sizes: '(max-width: 1040px) 100vw, 1040px',
						}}
						imgStyle={{ zIndex: '-1', opacity: 0.8 }}
						loading="eager"
						style={{ position: 'unset' }}
					/>
					<HeroImageText>
						<Title>Red Bull Music Academy Lectures</Title>
						<Subtitle>
							In-depth interviews with some of music&rsquo;s most creative and
							inspiring minds, spanning two decades and hundreds of artists
						</Subtitle>
						<Link to="/lectures">
							<WhiteButton>Learn more</WhiteButton>
						</Link>
					</HeroImageText>
				</HeroImage>
			</HeroImageContainer>
			<Welcome>
				<Subtitle welcome>
					Welcome to the Red Bull Music Academy archive, tracing the global
					music institution&rsquo;s more than 20-year history.
				</Subtitle>
			</Welcome>
			<OneToTwoGrid>
				<ImageLinkWrapper to="/lectures">
					<ImgItem imgSrc="https://media.redbullmusicacademy.com/assets/lectures.jpg" />
				</ImageLinkWrapper>
				<StoryText>
					<TopicTitles>
						Get inspired by some of music&rsquo;s most creative minds
					</TopicTitles>
					<Link to="/lectures">Lectures</Link>
				</StoryText>
			</OneToTwoGrid>
			<OneToTwoGrid reverse>
				<ImageLinkWrapper to="/alumni">
					<ImgItem imgSrc="https://media.redbullmusicacademy.com/assets/ALUMNI_1.jpg" />
				</ImageLinkWrapper>
				<StoryText>
					<TopicTitles>
						Find out more about the artists who passed through the
						Academy&rsquo;s doors
					</TopicTitles>
					<Link to="/alumni">Alumni</Link>
				</StoryText>
			</OneToTwoGrid>
			<OneToTwoGrid>
				<ImageLinkWrapper to="/about">
					<ImgItem imgSrc="https://media.redbullmusicacademy.com/assets/about.jpg" />
				</ImageLinkWrapper>
				<StoryText>
					<TopicTitles>
						Explore the milestone events and initiatives that shaped
						Red&nbsp;Bull Music&nbsp;Academy
					</TopicTitles>
					<Link to="/about">About</Link>
				</StoryText>
			</OneToTwoGrid>
			<OneToTwoGrid reverse>
				<LinkWrapper
					href="https://daily.redbullmusicacademy.com/"
					rel="noopener noreferrer"
					target="_blank"
				>
					<ImgItem imgSrc="https://media.redbullmusicacademy.com/assets/daily.jpg" />
				</LinkWrapper>
				<StoryText>
					<TopicTitles>
						Learn about the essential ideas, sounds and people that moved – and
						continue to move – our culture forward
					</TopicTitles>
					<a
						href="https://daily.redbullmusicacademy.com/"
						rel="noopener noreferrer"
						target="_blank"
					>
						Red&nbsp;Bull&nbsp;Music&nbsp;Academy&nbsp;Daily Archive
					</a>
				</StoryText>
			</OneToTwoGrid>
		</Layout>
	);
};

export default IndexPage;

ImgItem.propTypes = {
	imgSrc: PropTypes.string,
};
