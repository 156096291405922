import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Text, { m, ml, xxxxl } from '../styles/typography';
import Layout, { ContainerWithMargin } from '../components/layout';
import Grid from '../components/grid';
import SEO from '../components/seo';
import { colors, spacing, minWidthCss } from '../styles/theme';

const YearTitle = styled(Text.H2)`
	display: flex;
	justify-content: center;
	margin: 10rem 0 ${spacing[6]} 0;
	font-size: 45px;

	${minWidthCss.m`
    margin: 13.75rem 0 ${spacing[6]} 0;
    ${xxxxl};
  `}
`;

const AlumniImage = styled.div.attrs(props => ({
	style: {
		backgroundImage: `url(${props.url})`,
	},
}))`
	background-position: center;
	background-size: cover;
	margin-bottom: 10px;
	width: 160px;
	height: 180px;
	pointer-events: none;
`;

const HoverWrapper = styled.div`
	display: none;
	flex-direction: column;
	position: absolute;
	z-index: 10;
	top: 115%;
	left: 1.5%;
	background-color: ${colors.black};
`;

const Alumni = styled.a`
	color: ${colors.white};
	text-decoration: none;
	padding-top: 5px;

	&:hover > ${HoverWrapper} {
		display: flex;
	}

	> span {
		border-bottom: ${colors.white} 2px solid;
		${m};

		${minWidthCss.m`
    ${ml};
  `}
	}
	position: relative;
`;

const AlumniContainer = styled(ContainerWithMargin)`
	margin-bottom: ${spacing[6]};

	${minWidthCss.m`
    margin: 0 4.0625rem;
  `}
`;

const AlumniPage = ({ data }) => {
	const alumniData = data.allAlumniJson.nodes;

	return (
		<Layout>
			<SEO pathname="/alumni" title="Alumni" />
			<For each="academyYear" of={alumniData}>
				<YearTitle>{academyYear.value.title}</YearTitle>
				<AlumniContainer>
					<Grid columns={4} isTextOnly>
						<For each="alumni" index="index" of={academyYear.value.alumni}>
							<Alumni
								href={alumni.link}
								index={index}
								key={`${alumni.name}_${index}`}
								target="_blank"
							>
								<Text.Span>{alumni.name}</Text.Span>
								<HoverWrapper>
									<AlumniImage
										role="img"
										url={`"https://media.redbullmusicacademy.com/assets/${alumni.photo.id.replace(
											/\s/g,
											'%20'
										)}?w=200"`}
									/>
									<Text.Span size="xs">
										{alumni.name}, {alumni.hometown}
									</Text.Span>
								</HoverWrapper>
							</Alumni>
						</For>
					</Grid>
				</AlumniContainer>
			</For>
		</Layout>
	);
};

AlumniPage.propTypes = {
	data: PropTypes.shape({
		allAlumniJson: PropTypes.shape({
			nodes: PropTypes.arrayOf({
				value: PropTypes.objectOf({
					title: PropTypes.string,
					alumni: PropTypes.shape({
						name: PropTypes.string,
						hometown: PropTypes.string,
						photo: PropTypes.object,
						link: PropTypes.string,
					}),
				}),
			}),
		}),
	}).isRequired,
};
export default AlumniPage;

export const alumniQuery = graphql`
	query alumniQuery {
		allAlumniJson {
			nodes {
				value {
					title
					alumni {
						name
						hometown
						photo {
							id
						}
						link
					}
				}
			}
		}
	}
`;
