import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';

import { useScrollPosition } from '../hooks/useScrollPosition';
import ArrowUp from '../images/icons/arrow-up-gold';
import Text, { m, ml, l, xl } from '../styles/typography';
import { Button } from '../styles/buttons';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { colors, spacing, minWidthCss } from '../styles/theme';

const Container = styled.div`
	margin: 0 15px;
	position: relative;

	${minWidthCss.m`
    margin: 0 85px;
  `}
`;

const LetterSelector = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid white;
	background-color: ${colors.black};
	overflow-y: scroll;

	${minWidthCss.m`
    border-bottom: 2px solid white;
  `}
`;

const LetterSelectorItem = styled.a`
	transition-property: color;
	transition-duration: 0.3s;
	margin: 1rem 0;
	text-align: center;
	text-decoration: none;
	padding: ${spacing[0]};

	&:hover {
		color: ${colors.black};
		background-color: ${colors.white};
	}

	> span {
		${ml}

		${minWidthCss.m`
      ${l};
    `};
	}

	${minWidthCss.m`
    margin: 2rem 0;
  `};
`;

const Index = styled.div`
	visibility: hidden;
	height: 0;
`;

const LectureContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const LectureItem = styled(Link)`
	text-decoration: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 3rem;
	border-bottom: 1px ${colors.white} solid;
	border-top: ${props => (props.isTop ? '1px white solid' : 'none')};

	&:hover {
		color: ${colors.black};
		background-color: ${colors.white};
	}

	> span {
		${m};

		${minWidthCss.m`
        ${l};
    `};
	}

	${minWidthCss.m`
    height: ${spacing[4]};
    border-bottom: 2px ${colors.white} solid;
    border-top: ${props => (props.isTop ? '2px white solid' : 'none')};
`};
`;

const FilterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2rem 0;

	${minWidthCss.m`
    margin: ${spacing[5]} 0;
`};
`;

const FilterButton = styled(Button)`
	${ml};
	background-color: ${props =>
		(props.currentFilter === 'artist' && props.artistFilter) ||
		(props.currentFilter === 'year' && props.yearFilter)
			? `${colors.white}`
			: `${colors.black}`};
	color: ${props =>
		(props.currentFilter === 'artist' && props.artistFilter) ||
		(props.currentFilter === 'year' && props.yearFilter)
			? `${colors.black}`
			: `${colors.white}`};

	&:hover {
		color: ${colors.black};
		background-color: ${colors.white};
	}

	${minWidthCss.m`
    ${xl}
  `};
`;

const FilterButtonDivider = styled(Text.Span)`
	${ml};
	padding: 0.5rem;

	${minWidthCss.m`
  ${xl}
`};
`;

const GoToTop = styled.div`
	position: fixed;
	right: 30px;
	cursor: pointer;
	opacity: ${props => (props.showArrow ? 1 : 0)};
	transition: opacity 250ms;
`;

const LecturePage = () => {
	const { allLecturesJson, allMultipleLecturesJson } = useStaticQuery(
		graphql`
			query {
				allLecturesJson(sort: { fields: title }) {
					nodes {
						title
						year
						_id
						currentSlug
					}
				}
				allMultipleLecturesJson {
					nodes {
						year
						slug
						title
					}
				}
			}
		`
	);

	const [currentFilter, setCurrentFilter] = useState('artist');
	const [showArrow, setShowArrow] = useState(false);
	const lectureData = allLecturesJson.nodes;
	const artistsWithMultipleLectures = allMultipleLecturesJson.nodes;

	const setArtistFilter = () => setCurrentFilter('artist');
	const setYearFilter = () => setCurrentFilter('year');

	const goToTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	useScrollPosition(
		({ currPos }) => {
			if (currPos.y < -186) {
				setShowArrow(true);
			} else {
				setShowArrow(false);
			}
		},
		[showArrow]
	);

	const removeDuplicates = () => {
		const mergeData = lectureData.concat(artistsWithMultipleLectures);
		const duplicateMap = new Map();
		mergeData.forEach(item => {
			const { title } = item;
			duplicateMap.set(title, item);
		});
		return Array.from(duplicateMap.values());
	};

	const uniqueItems = removeDuplicates();

	const sortArtists = lectures => {
		const alphabetiseLectures = lectures.sort((a, b) => {
			if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
			if (b.title.toUpperCase() < a.title.toUpperCase()) return 1;
			return 0;
		});
		const reducedLectures = alphabetiseLectures.reduce((accum, item) => {
			const letter = item.title.charAt(0).toUpperCase();
			if (accum[letter]) accum[letter].push(item);
			else accum[letter] = [item];
			return accum;
		}, {});

		return Object.entries(reducedLectures).map(item => item);
	};

	const lectureItems = sortArtists(uniqueItems);

	const sortYears = lectures => {
		return lectures.reduce((accum, lecture) => {
			const { year } = lecture;

			return {
				...accum,
				...{ [year]: accum[year] ? accum[year] + 1 : 1 },
			};
		}, {});
	};

	const yearItems = sortYears(lectureData);

	return (
		<Layout>
			<SEO pathname="/lectures" title="Lectures" />
			<FilterContainer>
				<FilterButton
					artistFilter
					currentFilter={currentFilter}
					onClick={setArtistFilter}
				>
					Artist A-Z
				</FilterButton>
				<FilterButtonDivider> - </FilterButtonDivider>
				<FilterButton
					currentFilter={currentFilter}
					onClick={setYearFilter}
					yearFilter
				>
					Year
				</FilterButton>
			</FilterContainer>
			<Container>
				<Choose>
					<When condition={currentFilter === 'artist'}>
						<LetterSelector>
							<For each="letter" index="index" of={lectureItems}>
								<LetterSelectorItem
									href={`#${letter[0]}`}
									key={`${letter[0]}_${index}`}
								>
									<Text.Span>{letter[0]}</Text.Span>
								</LetterSelectorItem>
							</For>
						</LetterSelector>
						<GoToTop onClick={goToTop} role="button" showArrow={showArrow}>
							<ArrowUp />
						</GoToTop>
						<LectureContainer>
							<For each="lectureCollection" of={lectureItems}>
								<Index id={lectureCollection[0]} />
								<For each="lecture" index="index" of={lectureCollection[1]}>
									<Choose>
										<When condition={lecture.currentSlug}>
											<LectureItem
												key={`${lecture}_${index}`}
												to={`/lectures/${lecture.currentSlug}`}
											>
												<Text.Span>{lecture.title}</Text.Span>
												<Text.Span>({lecture.year})</Text.Span>
											</LectureItem>
										</When>
										<Otherwise>
											<LectureItem
												key={`${lecture}_${index}`}
												to={`/artist/${lecture.slug}`}
											>
												<Text.Span>{lecture.title}</Text.Span>
												<Text.Span>({lecture.year})</Text.Span>
											</LectureItem>
										</Otherwise>
									</Choose>
								</For>
							</For>
						</LectureContainer>
					</When>
					<Otherwise>
						<For
							each="year"
							index="index"
							of={Object.entries(yearItems).reverse()}
						>
							<If condition={year[0] !== 'null'}>
								<LectureItem
									isTop={year[0] === '2019'}
									key={`${year[0]}_${index}`}
									to={`/lectures/${year[0]}`}
								>
									<Text.Span>{year[0]}</Text.Span>
									<Text.Span>({year[1]})</Text.Span>
								</LectureItem>
							</If>
						</For>
					</Otherwise>
				</Choose>
			</Container>
		</Layout>
	);
};

export default LecturePage;
