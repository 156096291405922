const { ENGINE_KEY } = process.env;
const API_URL =
	'https://search-api.swiftype.com/api/v1/public/engines/search.json';

const handleResponse = res => {
	if (!res.ok) {
		throw new Error(res.statusText);
	}
	return res.json();
};

const objectToParams = (obj, parent) =>
	Object.entries(obj).map(([name, value]) => {
		if (!value) return '';
		if (typeof value === 'object') {
			if (value.length) {
				return value
					.map(item =>
						parent ? `${parent}[${name}][]=${item}` : `${name}[]=${item}`
					)
					.join('&');
			}
			return objectToParams(value, parent ? `${parent}[${name}]` : name).join(
				'&'
			);
		}
		return parent ? `${parent}[${name}]=${value}` : `${name}=${value}`;
	});

export const getSearchResults = async (query, tags = [], page) => {
	const perPage = 18;
	const params = objectToParams({
		engine_key: ENGINE_KEY,
		q: query,
		per_page: perPage,
		page,
		filters: {
			pages: {
				tags: {
					type: 'and',
					values: tags,
				},
			},
		},
	})
		.filter(value => value)
		.join('&');

	return fetch(`${API_URL}?${params}`).then(handleResponse);
};
