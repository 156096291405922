import React from 'react';

const CloseIcon = props => (
	<svg fill="none" height={18} viewBox="0 0 18 18" width={18} {...props}>
		<circle cx={9} cy={9} fill="#fff" r={9} />
		<path
			d="M5 12.062l.912.928 3.127-3.126 3.143 3.127.928-.912-3.143-3.143 3.025-3.008L12.064 5 9.039 8.024 6.023 5.007l-.912.912 3.016 3.017L5 12.062z"
			fill="#000"
		/>
	</svg>
);

export default CloseIcon;
