import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from '../styles/typography';
import { spacing, colors } from '../styles/theme';

const StyledCheckbox = styled.div`
	width: ${spacing[1]};
	height: ${spacing[1]};
	border-radius: 50%;
	display: inline-block;
	vertical-align: top;
	margin-right: ${spacing[0]};
	background-color: ${props =>
		props.checked ? `${colors.white}` : `${colors.black}`};
	transition: all 0.1s;
`;

const CustomCheckbox = styled(Text.Label)`
	margin-bottom: ${spacing[1]};
	flex-basis: 50%;
	text-transform: capitalize;
	/* need a focus state for accessibility  */
	> input:focus + ${StyledCheckbox} {
		outline: 3px ${colors.mediumGrey} solid;
		outline-offset: 2px;
	}
	> input:hover + ${StyledCheckbox} {
		background-color: ${colors.white};
	}
	/* hides the input but still leaves it accessible */
	> input {
		position: absolute;
		width: ${spacing[1]};
		height: ${spacing[1]};
		opacity: 0;
	}
	@media (min-width: 900px) {
		flex-basis: 20%;
	}
`;

const Checkbox = ({ name, checked = false, onChange }) => (
	<CustomCheckbox key={name}>
		<input name={name} onChange={onChange} type="checkbox" />
		<StyledCheckbox checked={checked} />
		{name.replace(/-/g, ' ')}
	</CustomCheckbox>
);

Checkbox.propTypes = {
	name: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

export default Checkbox;
