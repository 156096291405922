import React from 'react';

const SearchIcon = props => (
	<svg fill="none" height={31} viewBox="0 0 31 31" width={31} {...props}>
		<path
			clipRule="evenodd"
			d="M18.94 16.96a8.5 8.5 0 10-1.817 1.86l6.424 6.424 1.838-1.839-6.445-6.445zm-2.747-9.11a5.9 5.9 0 11-8.344 8.344 5.9 5.9 0 018.344-8.344z"
			fill="#fff"
			fillRule="evenodd"
		/>
	</svg>
);

export default SearchIcon;
