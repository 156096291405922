import React from 'react';

const PlayIcon = props => (
	<svg fill="none" height={91} width={91} {...props}>
		<path d="M68 46L32 66.785v-41.57L68 46z" fill="#fff" />
		<circle cx={45.5} cy={45.5} r={43.5} stroke="#fff" strokeWidth={4} />
	</svg>
);

export default PlayIcon;
