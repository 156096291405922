import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout, { ContainerWithMargin } from '../components/layout';
import SEO from '../components/seo';

import { colors, fontSize, minWidthCss } from '../styles/theme';
import Text from '../styles/typography';

const StyledLink = styled(Link)`
	text-decoration: underline;
`;

const Title = styled(props => <Text.H1 {...props} />)`
	color: ${colors.red};
	font-size: ${fontSize[8]};
	animation: flash 0.7s infinite;
`;

const ErrorMessage = styled.span`
  display: block;
  ${minWidthCss.sm`
    display: inline;
  `}
  font-size: ${fontSize[5]};
  color: ${colors.white};
`;

const NotFoundPage = () => (
	<Layout with404>
		<SEO title="404 Not Found" />
		<ContainerWithMargin>
			<Title>
				404 <ErrorMessage>Not Found</ErrorMessage>
			</Title>
			<Text.P size="ml">
				The page you are looking for does not exist. Please try the URL again,
				or <StyledLink to="/">head to our home page</StyledLink>.
			</Text.P>
		</ContainerWithMargin>
	</Layout>
);

export default NotFoundPage;
