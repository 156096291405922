import React from 'react';
import styled from 'styled-components';

import Layout, { ContainerWithMargin } from '../components/layout';
import SEO from '../components/seo';
import SearchInput from '../components/search-input';
import LinkItems from '../components/link-items';
import useSearch from '../hooks/useSearch';
import Filter from '../components/filter';
import SortFormat from '../components/sort-format';

const FilterSearchWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const SearchPage = () => {
	const [results, fetchResults, fetchPage, filterByTags] = useSearch(null);
	const onSubmit = type => tags => {
		const tagNames = Object.keys(tags);
		filterByTags({ type, tags: tagNames.length ? tagNames : [] });
	};
	return (
		<Layout>
			<SEO pathname="/search" title="Search" />
			<ContainerWithMargin>
				<SearchInput
					onSearch={fetchResults}
					resultAmount={results && results.recordCount}
					resultTotal={results && results.totalCount}
				/>
			</ContainerWithMargin>
			<If condition={results}>
				<FilterSearchWrapper>
					<Filter onSubmit={onSubmit('filter')} />
					<SortFormat onSubmit={onSubmit('sort')} />
				</FilterSearchWrapper>
				<LinkItems
					columns={3}
					data={results.allItems}
					hasNextPage={results.hasNextPage}
					onNextPage={fetchPage}
				/>
			</If>
		</Layout>
	);
};

export default SearchPage;
