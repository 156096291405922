import React from 'react';
import styled from 'styled-components';

import Text, { base } from '../styles/typography';
import { fontSize, minWidthCss, spacing } from '../styles/theme';

const FooterContainer = styled.footer`
	position: absolute;
	bottom: 0;

	width: 100%;

	padding: 0 ${spacing[2]} ${spacing[2]};

	font-size: ${fontSize[1]};
	text-align: center;

	${minWidthCss.sm`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    font-size: ${fontSize[2]};
  `}
`;

const CopyrightNotice = styled(Text.Span)`
	display: block;
	${minWidthCss.sm`
    display: inline;
  `}
`;

const FooterLink = styled.a`
	${base}
	& + &::before {
		content: '|';
		margin: 0 ${spacing[0]};
	}

	${minWidthCss.sm`
    &::before,
    & + &::before {
      content: '|';
      margin: 0 ${spacing[1]};
    }
  `};
`;

const FooterLinks = [
	{
		name: 'Terms of Use',
		path:
			'https://policies.redbull.com/policies/RedBull.com_International/201909120244/en/terms.html',
	},
	{
		name: 'Privacy Policy',
		path:
			'https://policies.redbull.com/policies/RedBull.com_International/201909120244/en/privacy.html',
	},
	{
		name: 'Imprint',
		path:
			'https://policies.redbull.com/policies/RedBull.com_International/201909120244/en/imprint.html',
	},
];

const Footer = () => (
	<FooterContainer>
		<CopyrightNotice>© Red Bull 2019</CopyrightNotice>
		<For each="item" of={FooterLinks}>
			<FooterLink
				href={item.path}
				key={item.path}
				rel="noopener noreferrer"
				target="_blank"
			>
				{item.name}
			</FooterLink>
		</For>
	</FooterContainer>
);

export default Footer;
