import { css } from '@nfront/global-styles';

import { fontstackSansSerif } from './typography';

import { root, colors } from './theme';

const globalStyles = css`
	body {
		font-family: ${fontstackSansSerif};
		margin: 0;
		text-rendering: optimizeLegibility;
		width: 100%;
		background-color: ${colors.black};
		color: ${colors.white};
	}

	html {
		font-size: ${`${root}px`};
		font-smooth: initial;
		-webkit-font-smoothing: antialiased;
	}

	a {
		font-family: ${fontstackSansSerif};
		color: ${colors.white};
		cursor: pointer;
		text-decoration: none;
	}

	.active > span {
		background-color: ${colors.white};
		color: ${colors.black};
	}

	@keyframes flash {
		0% {
			color: ${colors.red};
		}
		100% {
			color: ${colors.white};
		}
	}
`;

export default globalStyles;
