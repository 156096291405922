import { useState, useEffect } from 'react';

const usePagination = (initialPage = 1, onNextPage) => {
	const [page, setPage] = useState(initialPage);
	const nextPage = () => {
		setPage(page + 1);
	};
	if (onNextPage) {
		useEffect(() => onNextPage(page), [page]);
	}
	return [page, nextPage];
};

export default usePagination;
