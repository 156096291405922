import styled from 'styled-components';

import { colors, spacing, minWidthCss } from './theme';
import { fontstackSansSerif, m, ml } from './typography';
import './typography.css';

export const Button = styled.button`
	font-family: ${fontstackSansSerif};
	${props => (props.small ? m : ml)};
	align-items: center;
	color: ${colors.white};
	background-color: transparent;
	cursor: pointer;
	border: none;
	border-bottom: ${props => (props.underline ? 'white solid 2px' : 'none')};
`;

export const WhiteButton = styled(Button)`
	${m};
	background-color: ${colors.white};
	color: ${colors.black};
	padding: 0.375rem ${spacing[1]};

	${minWidthCss.m`
   ${ml}
   padding: ${spacing[0]} ${spacing[1]};
  `};
`;
