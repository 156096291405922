import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from './theme';
import './typography.css';

export const fontstackSansSerif = 'MaisonNeue, Helvetica, Arial, sans-serif';
export const fontstackSerif =
	'Portrait, Times New Roman, Times, Baskerville, Georgia, serif';

// sizes px / rem
// [8]: xxxxl 120px / 7.5rem
// [7]: xxxl  100px / 6.25rem
// [6]: xxl   60px / 3.75rem
// [5]: xl    45px / 2.8125rem
// [4]: l     30px / 1.875rem
// [3]: ml    20px / 1.25rem
// [2]: m     16px / 1rem
// [1]: s     12px / 0.75rem
// [0]: xs    10px / 0.625rem

export const xxxxl = css`
	font-size: 7.5rem;
	line-height: 110%;
`;

export const xxxl = css`
	font-size: 6.25rem;
	line-height: 110%;
`;

export const xxl = css`
	font-size: 3.75rem;
	line-height: 110%;
`;

export const xl = css`
	font-size: 2.8125rem;
	line-height: 111%;
`;

export const l = css`
	font-size: 1.875rem;
	line-height: 150%;
`;

export const ml = css`
	font-size: 1.25rem;
	line-height: 150%;
`;

export const m = css`
	font-size: 1rem;
	line-height: 150%;
`;

export const s = css`
	font-size: 0.75rem;
	line-height: 150%;
`;

export const xs = css`
	font-size: 0.625rem;
	line-height: 150%;
`;

const sizes = {
	xxxxl,
	xxxl,
	xxl,
	xl,
	l,
	ml,
	m,
	s,
	xs,
};

export const base = css`
	margin: 0;
	font-family: ${props => (props.serif ? fontstackSerif : fontstackSansSerif)};
	${props => (props.bold ? 'font-weight: bold' : '')};
	${props => (props.italic ? 'font-style: italic' : '')};
	${props => (props.align ? `text-align: ${props.align}` : '')};
	${props => sizes[props.size]};
	${props => (props.marginTop ? `margin-top: ${props.marginTop}` : '')};
	${props =>
		props.marginBottom ? `margin-bottom: ${props.marginBottom}` : ''};
	${props => (props.marginLeft ? `margin-left: ${props.marginLeft}` : '')};
	${props => (props.marginRight ? `margin-right: ${props.marginRight}` : '')};
	color: ${props => (props.color ? colors[props.color] : 'inherit')};
	${props => (props.opacity ? `opacity: ${props.opacity}` : '')};
	font-weight: normal;
`;

const H1 = styled.h1`
	${base}
`;

const H2 = styled.h2`
	${base}
`;

const H3 = styled.h3`
	${base}
`;

const P = styled.p`
	${base}
`;

const Span = styled.span`
	${base}
`;

const Li = styled.li`
	${base}
`;

const Label = styled.label`
	${base}
`;

const Text = {};

Text.H1 = props => <H1 {...props} />;
Text.H2 = props => <H2 {...props} />;
Text.H3 = props => <H3 {...props} />;
Text.P = props => <P {...props} />;
Text.Span = props => <Span {...props} />;
Text.Li = props => <Li {...props} />;
Text.Label = props => <Label {...props} />;

const propTypes = {
	children: PropTypes.node,
	size: PropTypes.oneOf([
		'xs',
		's',
		'm',
		'ml',
		'l',
		'xl',
		'xxl',
		'xxxl',
		'xxxxl',
	]),
};

H1.propTypes = H2.propTypes = H3.propTypes = P.propTypes = Span.propTypes = Li.propTypes = propTypes;

export default Text;
