import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { useScrollPosition } from '../hooks/useScrollPosition';
import useWindowSize from '../hooks/useWindowSize';
import ColorLogo from '../images/rbma-logo-color';
import SearchIcon from '../images/icons/search-icon';
import MobileLinkIcon from '../images/icons/mobile-link-icon';
import Text, { ml, l } from '../styles/typography';
import { colors, minWidthCss, spacing } from '../styles/theme';

const NavContainer = styled.nav`
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 10;
	background-color: ${props =>
		props.isHomepageBanner ? 'transparent' : colors.black};

	${minWidthCss.l`
  transform: ${props =>
		props.showNav ? 'translateY(0) ' : 'translateY(-100%)'};
  transition: transform 0.5s;
  `}
`;

const LinkWrapper = styled.div`
	display: flex;
	flex-direction: ${props => (props.mobile ? 'column' : 'row')};
	align-items: ${props => (props.mobile ? 'flex-start' : 'center')};
	justify-content: space-between;
	padding: ${props => (props.mobile ? `0 ${spacing[1]}` : `0 ${spacing[3]}`)};

	> a {
		display: flex;
		align-items: center;
	}

	${minWidthCss.l`
    width: ${props => (props.logoWrapper ? '24%' : '65%')};
  `}

	${minWidthCss.xl`
    width: ${props => (props.logoWrapper ? '18%' : '65%')};
  `}
`;

const LinkItem = styled(Text.Span)`
	text-decoration: none;
	${props => (props.mobile ? l : ml)};

	padding: ${spacing[0]};

	&:hover {
		background-color: ${colors.white};
		color: ${colors.black};
	}
`;

const DesktopMenuWrapper = styled.div`
	display: none;
	width: 100%;

	${minWidthCss.l`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${spacing[5]};
  `}
`;

// MOBILE STYLES
const MobileNavigationContainer = styled.div`
	display: flex;
	align-items: center;
	padding: ${spacing[2]} ${spacing[1]};
	justify-content: space-between;
	${props => (props.openMenu ? `background-color: ${colors.black}` : '')};

	${minWidthCss.l`
    display: none;
  `};
`;

const MobileButtonWrapper = styled.div`
	display: flex;

	> span {
		margin-left: ${spacing[1]};
	}
`;

const Hamburger = styled.div`
	cursor: pointer;
	width: 24px;
	transform: translateY(1px);
	height: 24px;
	position: relative;
	top: 1px;
	z-index: 5000;
	> span {
		background: ${colors.white};
		height: 2px;
		transition: all 0.35s;
		display: block;
		opacity: 1;
		position: absolute;
		left: 5%;
		width: 90%;
	}

	> span:nth-child(1) {
		top: 18%;
		${props =>
			props.openMenu ? 'transform: translateY(6.72px) rotate(225deg)' : ''};
		${props => (props.openMenu ? 'opacity: 1' : '')};
	}
	> span:nth-child(2) {
		top: 47%;
		${props => (props.openMenu ? 'opacity: 0' : '')};
	}

	> span:nth-child(3) {
		bottom: 16%;
		${props =>
			props.openMenu ? 'transform: translateY(-6.72px) rotate(-225deg)' : ''};
		${props => (props.openMenu ? 'opacity: 1' : '')};
	}
`;

const MobileMenuContainer = styled.div`
	background-color: ${colors.black};
	top: 60px;
	left: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 20;
	overflow-y: auto;
	overflow-x: hidden;
	opacity: ${props => (props.openMenu ? 1 : 0)};
	transform: ${props =>
		props.openMenu ? 'translateY(0) ' : 'translateY(-110%)'};
	transition: opacity 0.5s;
	padding-top: 5rem;

	${minWidthCss.l`
    display: none;
  `}
`;

const Paths = {
	Home: '/',
	About: '/about',
	Lectures: '/lectures',
	Alumni: '/alumni',
	Daily: 'https://daily.redbullmusicacademy.com/',
	RBMusic: 'https://www.redbull.com/int-en/tags/music',
	Search: '/search',
};

const SearchIconHomepage = styled(SearchIcon)`
	height: 1.4375rem;
`;

const HamburgerMenu = ({ toggleMenu, openMenu }) => (
	<MobileNavigationContainer openMenu={openMenu}>
		<MobileButtonWrapper>
			<Hamburger onClick={toggleMenu} openMenu={openMenu}>
				<span />
				<span />
				<span />
			</Hamburger>
			<Text.Span size="ml">{openMenu ? 'Close' : 'Menu'}</Text.Span>
		</MobileButtonWrapper>
		<Link to={Paths.Home}>
			<ColorLogo />
		</Link>
	</MobileNavigationContainer>
);

HamburgerMenu.propTypes = {
	toggleMenu: PropTypes.func,
	openMenu: PropTypes.bool,
};

const NavigationLinks = ({ mobile, closeMenu }) => (
	<>
		<LinkWrapper mobile={mobile}>
			<Link activeClassName="active" to={Paths.About}>
				<LinkItem mobile={mobile} onClick={closeMenu}>
					About
				</LinkItem>
			</Link>
			<Link activeClassName="active" onClick={closeMenu} to={Paths.Lectures}>
				<LinkItem mobile={mobile}>Lectures</LinkItem>
			</Link>
			<Link activeClassName="active" onClick={closeMenu} to={Paths.Alumni}>
				<LinkItem mobile={mobile}>Alumni</LinkItem>
			</Link>
			<If condition={mobile}>
				<Link
					activeClassName="active"
					onClick={closeMenu}
					style={{ marginBottom: '70px' }}
					to={Paths.Search}
				>
					<SearchIcon />
					<LinkItem mobile={mobile}>Search</LinkItem>
				</Link>
			</If>
			<a href={Paths.Daily}>
				<If condition={mobile}>
					<MobileLinkIcon />
				</If>
				<LinkItem mobile={mobile}>Daily Archive</LinkItem>
			</a>
			<a href={Paths.RBMusic}>
				<If condition={mobile}>
					<MobileLinkIcon />
				</If>
				<LinkItem mobile={mobile}>Red Bull Music</LinkItem>
			</a>
		</LinkWrapper>
		<If condition={!mobile}>
			<LinkWrapper logoWrapper mobile={mobile}>
				<Link activeClassName="active" to={Paths.Search}>
					<SearchIconHomepage />
					<LinkItem mobile={mobile}>Search</LinkItem>
				</Link>
				<Link to={Paths.Home}>
					<ColorLogo />
				</Link>
			</LinkWrapper>
		</If>
	</>
);

NavigationLinks.propTypes = {
	closeMenu: PropTypes.func,
	mobile: PropTypes.bool,
};

const Navigation = ({ isHomepageBanner = false }) => {
	const [showNav, setShowNav] = useState(true);
	const [openMenu, setOpenMenu] = useState(false);
	const size = useWindowSize();

	useEffect(() => {
		if (size.width >= 1200) {
			setOpenMenu(false);
			document.body.style.overflow = 'initial';
		}
	}, [size.width]);

	const toggleMenu = () => {
		setOpenMenu(!openMenu);
		if (!openMenu) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'initial';
		}
	};

	const closeMenu = () => {
		setOpenMenu(false);
		document.body.style.overflow = 'initial';
	};

	useScrollPosition(
		({ currPos, prevPos }) => {
			if (currPos.y < prevPos.y) {
				setShowNav(false);
			} else {
				setShowNav(true);
			}
		},
		[showNav]
	);

	return (
		<NavContainer isHomepageBanner={isHomepageBanner} showNav={showNav}>
			<HamburgerMenu openMenu={openMenu} toggleMenu={toggleMenu} />
			<MobileMenuContainer openMenu={openMenu}>
				<NavigationLinks closeMenu={closeMenu} mobile />
			</MobileMenuContainer>
			<DesktopMenuWrapper>
				<NavigationLinks />
			</DesktopMenuWrapper>
		</NavContainer>
	);
};

Navigation.propTypes = {
	isHomepageBanner: PropTypes.bool,
};

export default Navigation;
