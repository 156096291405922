import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import moment from 'moment';

import Text from '../styles/typography';

import { Button } from '../styles/buttons';

import DataTransformer from '../utils/DataTransformer';
import { minWidthCss, colors } from '../styles/theme';
import usePagination from '../hooks/usePagination';

import { ContainerWithMargin } from './layout';

import Grid from './grid';

const COLUMN_FACTOR = 6;

const Title = styled(({ isExternal, url, children, ...props }) => (
	<Choose>
		<When condition={isExternal}>
			<a href={url} {...props}>
				{children}
			</a>
		</When>
		<Otherwise>
			<Link to={url} {...props}>
				{children}
			</Link>
		</Otherwise>
	</Choose>
))`
	text-decoration: none;
	color: black;
	font-size: 20px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	color: ${colors.white};

	> img {
		width: 100%;
	}
`;

const Image = styled.div`
	background-image: url(${props => props.url});
	background-position: center;
	background-size: cover;
	margin-bottom: 10px;
	padding-top: 66.77%;
`;

const Wrapper = styled.div`
	margin: 20px 0 40px;

	${minWidthCss.sm`
    margin: 40px 20px 80px;
  `}
`;

const getBase = docType => {
	switch (docType) {
		case 'academyProject':
			return '/about/projects';
		case 'lecture':
			return '/lectures';
		case 'author':
			return 'https://daily.redbullmusicacademy.com/author';
		case 'story':
		case 'coverStory':
		case 'collection':
			return 'https://daily.redbullmusicacademy.com';
		default:
			return '';
	}
};

const getUrl = (docType, [slug], date) => {
	if (docType !== 'story' && docType !== 'coverStory') {
		return `${getBase(docType)}/${slug}`;
	}
	const dateObj = moment(date);
	return `${getBase(docType)}/${dateObj.year()}/${dateObj.format(
		'MM'
	)}/${slug}`;
};

const LinkItem = ({
	teaserImage,
	slug,
	title,
	teaserTitle,
	teaserSubtitle,
	date,
	type,
}) => {
	const url = getUrl(type, slug, date);
	return (
		<Title isExternal={url.charAt(0) !== '/'} key={slug[0]} url={url}>
			<Image
				aria-labelledby={title || teaserTitle}
				role="img"
				url={
					teaserImage
						? `"https://media.redbullmusicacademy.com/assets/${teaserImage.id.replace(
								/\s/g,
								'%20'
						  )}?w=500"`
						: '/images/empty-placeholder.jpg'
				}
			/>
			<Text.Span>
				{title || teaserTitle}
				<If condition={teaserSubtitle}> → {teaserSubtitle}</If>
			</Text.Span>
		</Title>
	);
};

const LinkItems = ({ data, columns, onNextPage, hasNextPage }) => {
	const itemsPerPage = columns * COLUMN_FACTOR;
	const [page, nextPage] = usePagination();

	const loadMore = async evt => {
		evt.preventDefault();
		if (onNextPage) onNextPage(page + 1);
		nextPage();
	};

	const { items, hasMore } = DataTransformer.from(data).getDataByPage(
		page,
		itemsPerPage
	);

	return (
		<>
			<ContainerWithMargin>
				<Choose>
					<When condition={items.length}>
						<Grid columns={columns}>
							<For each="item" index="index" of={items}>
								<LinkItem key={`${item.title}_${index}`} {...item} />
							</For>
						</Grid>
						<If condition={hasNextPage || hasMore}>
							<Wrapper>
								<Button onClick={loadMore}>Load more</Button>
							</Wrapper>
						</If>
					</When>
					<Otherwise>
						<Wrapper>
							<Text.H2>No matches found.</Text.H2>
						</Wrapper>
					</Otherwise>
				</Choose>
			</ContainerWithMargin>
		</>
	);
};

LinkItem.propTypes = {
	teaserImage: PropTypes.shape({
		id: PropTypes.string,
		space: PropTypes.string,
		mimetype: PropTypes.string,
	}),
	slug: PropTypes.arrayOf(PropTypes.string).isRequired,
	title: PropTypes.string,
	teaserTitle: PropTypes.string,
	teaserSubtitle: PropTypes.string,
	date: PropTypes.string,
	type: PropTypes.string,
};

LinkItems.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.number.isRequired,
	randomize: PropTypes.bool,
	onNextPage: PropTypes.func,
	hasNextPage: PropTypes.bool,
};

export default LinkItems;
