import { useState, useEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

const useWindowSize = () => {
	const getSize = () => ({
		width: isBrowser ? window.innerWidth : undefined,
		height: isBrowser ? window.innerHeight : undefined,
	});

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		const handleResize = () => setWindowSize(getSize());
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
};

export default useWindowSize;
