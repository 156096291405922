import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Text from '../styles/typography';
import { Button } from '../styles/buttons';
import { breakpoints, colors, spacing } from '../styles/theme';

import FilterMobile from './filter-mobile';
import Checkbox from './checkbox';
import SelectedTags from './selected-tags';

const FilterContainer = styled.div`
	position: relative;
	display: none;
	@media (min-width: ${breakpoints.sm}) {
		display: block;
		width: 75%;
	}
`;

const Menu = styled.div`
	background-color: ${colors.darkGrey};
	opacity: ${props => (props.showFilter ? '1' : '0')};
	z-index: ${props => (props.showFilter ? '10' : '-10')};
	position: absolute;
	width: 100vw;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
	transition: opacity 0.3s;
	@media (min-width: ${breakpoints.sm}) {
		height: auto;
	}
`;

const CategoryButton = styled(Button)`
	background-color: ${props =>
		props.selected ? `${colors.darkGrey}` : 'transparent'};
	padding: ${spacing[0]} ${spacing[1]};
	border-bottom: none;
	&:hover {
		color: ${colors.grey};
	}
`;

const Arrow = styled(Text.Span)`
	transform: ${props => (props.selected ? 'rotate(-180deg)' : 'rotate(0)')};
	transition: transform 0.3s;
	margin-left: ${spacing[0]};
	display: inline-block;
`;

const TagWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 30px 70px;
`;

const ButtonWrapper = styled.div`
	margin: 0 20px;

	@media (min-width: ${breakpoints.m}) {
		margin: 0 55px;
	}
`;

const fromEntries = entries =>
	entries.reduce((result, [key, value]) => ({ ...result, [key]: value }), {});
const Filter = ({ onSubmit }) => {
	const [showFilter, setShowFilter] = useState(false);
	const [checkedTags, setCheckedTags] = useState({});
	const [showCategories, setCategoriesMobile] = useState(true);

	useEffect(() => {
		const tagsToSubmit = Object.entries(checkedTags).filter(
			tag => tag[1] === true
		);
		onSubmit(fromEntries(tagsToSubmit));
	}, [checkedTags]);

	const data = useStaticQuery(graphql`
		query TagsQuery {
			allTagsJson {
				nodes {
					category
					tags {
						tag
					}
				}
			}
		}
	`);

	const [activeTab, setTab] = useState(data.allTagsJson.nodes[0].category);

	const handleReset = tag => {
		if (tag === 'all') {
			// need to clear all checked tags as well as reset the filtered data
			setCheckedTags({});
		} else {
			setCheckedTags({
				...checkedTags,
				[tag]: false,
			});
		}
	};

	const handleSelect = (category, selected) => () => {
		if (!showFilter) setShowFilter(true);
		if (selected) {
			// need to filter only the tags that are "true"
			const tagsToSubmit = Object.entries(checkedTags).filter(
				([_, isEnabled]) => isEnabled //eslint-disable-line
			);

			onSubmit(fromEntries(tagsToSubmit));
			setShowFilter(false);
		}
		setTab(category);
	};

	const handleChange = event => {
		const name = event.target.name.toLowerCase();
		setCheckedTags({
			...checkedTags,
			[name]: event.target.checked,
		});
	};

	return (
		<>
			<FilterMobile
				activeTab={activeTab}
				checkedTags={checkedTags}
				data={data.allTagsJson.nodes}
				handleChange={handleChange}
				handleReset={handleReset}
				handleSelect={handleSelect}
				setCategoriesMobile={setCategoriesMobile}
				setShowFilter={setShowFilter}
				setTab={setTab}
				showCategories={showCategories}
				showFilter={showFilter}
			/>
			<FilterContainer showFilter={showFilter}>
				<ButtonWrapper>
					<For each="tagCategory" of={data.allTagsJson.nodes}>
						<CategoryButton
							key={tagCategory.category}
							onClick={handleSelect(
								tagCategory.category,
								showFilter && activeTab === tagCategory.category
							)}
							selected={showFilter && activeTab === tagCategory.category}
						>
							<Text.Span size="m">{tagCategory.category}</Text.Span>
							<Arrow
								selected={showFilter && activeTab === tagCategory.category}
								size="ml"
							>
								↓
							</Arrow>
						</CategoryButton>
					</For>
				</ButtonWrapper>
				<Menu showFilter={showFilter}>
					<For each="tagCategory" index="index" of={data.allTagsJson.nodes}>
						<If condition={tagCategory.category === activeTab}>
							<TagWrapper key={`${tagCategory.tag}_${index}`}>
								<For each="tagName" index="index" of={tagCategory.tags}>
									<Checkbox
										checked={checkedTags[tagName.tag]}
										key={`${tagName.tag}_${index}`}
										name={tagName.tag}
										onChange={handleChange}
									/>
								</For>
							</TagWrapper>
						</If>
					</For>
					<SelectedTags checkedTags={checkedTags} handleReset={handleReset} />
				</Menu>
				<SelectedTags checkedTags={checkedTags} handleReset={handleReset} />
			</FilterContainer>
		</>
	);
};

Filter.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default Filter;
