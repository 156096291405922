import { useState, useEffect } from 'react';

import { getSearchResults } from '../utils/search';

const mergePages = (current, add) => {
	return {
		allItems: [...current.allItems, ...add.allItems],
		totalCount: add.totalCount,
		recordCount: current.recordCount + add.recordCount,
		hasNextPage: add.hasNextPage,
	};
};

const getAllTags = tagsByType =>
	Object.values(tagsByType).reduce((all, current) => [...all, ...current], []);

const useSearch = () => {
	const [results, setResults] = useState(null);
	const [query, setQuery] = useState(null);
	const [tagsByType, setTagsByType] = useState({});

	const doSearch = async (page = 1) => {
		const searchResults = await getSearchResults(
			query,
			getAllTags(tagsByType),
			page
		);
		const totalCount = Object.values(searchResults.info).reduce(
			(all, current) => all + current.total_result_count,
			0
		);
		const totalPages = Object.values(searchResults.info).reduce(
			(total, current) =>
				current.num_pages > total ? current.num_pages : total,
			0
		);
		const allItems = Object.values(searchResults.records)
			.reduce((all, current) => [...all, ...current], [])
			.map(item => ({
				title: item.title,
				teaserSubtitle: item.subtitle,
				teaserImage: {
					id: item.image,
				},
				slug: [item.slug],
				type: item.type,
				date: item.date,
			}));
		return {
			allItems,
			totalCount,
			recordCount: searchResults.record_count,
			hasNextPage: page < totalPages,
		};
	};

	const filterByTag = ({ type, tags }) => {
		setTagsByType({
			...tagsByType,
			[type]: tags,
		});
	};

	const fetchPage = async page => {
		if (!results.hasNextPage) return;
		const pageResults = await doSearch(page);
		setResults(mergePages(results, pageResults));
	};

	useEffect(() => {
		if (query) {
			doSearch().then(setResults);
		} else {
			setResults(null);
		}
	}, [query]);

	useEffect(() => {
		if (query) {
			doSearch().then(setResults);
		}
	}, [tagsByType]);

	return [results, setQuery, fetchPage, filterByTag];
};

export default useSearch;
